import {
    createBrowserRouter,
    RouterProvider,
    Outlet,
    createRoutesFromElements,
    Route,
    ScrollRestoration, 
  } from "react-router-dom";
  import Header from "./Components/Header/Header";
  import Footer from "./Components/Footer/Footer";
  import Home from "../src/Pages/Home/Home";
  
  import { ToastContainer } from "react-toastify";
  import About from "./Pages/About/About";
  import Contact from "./Pages/Contact/Contact";
  import Services from "./Pages/Services/Services";
import Avia from "./Pages/Avia/Avia";
import Tours from "./Pages/Tours/Tours";
import ToursDetail from "./Pages/ToursDetail/ToursDetail";
import AdminPanel from './Pages/admin/AdminPanel/AdminPanel'
import Protected from './Pages/admin/Protected/Protected'
import Dashboard from './Pages/admin/Dashboard/Dashboard'
import DashboardOffer from './Pages/admin/DashboardOffer/DashboardOffer'
import DashboardOrders from './Pages/admin/DashboardOrders/DashboardOrders'
import DashboardContact from './Pages/admin/DashboardContact/DashboardContact'
import DashboardPhotos from './Pages/admin/DashboardPhotos/DashboardPhotos'
import EditUser from './Pages/admin/EditUser/EditUser'
import EditPhotos from './Pages/admin/EditPhotos/EditPhotos'
import CreateUser from './Pages/admin/CreateUser/CreateUser'
import { FloatingWhatsApp } from "react-floating-whatsapp";
import WhatsappMenu from './Components/Whatsapp.js'
  
  
     
  const Layout = () => {
    return (
      <div>
         <ToastContainer
          position="top-right"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false} 
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable 
          pauseOnHover
          theme="colored"
        /> 
        <ScrollRestoration />
        <Outlet />
        <Footer />
      </div>
    );
  };
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route path="/" element={<Layout />}>
          {/* ==================== Header Navlink Start here =================== */}
          <Route path="/" element={ <Home />}></Route>
          <Route path="/services" element={ <Services/>}></Route>
          <Route path="/avia" element={ <Avia/>}></Route>
          <Route path="/tours" element={ <Tours/>}></Route>
          <Route path="/contact" element={ <Contact/>}></Route>
          <Route path="/about" element={ <About/>}></Route>
          <Route path="/tours/:id" element={ <ToursDetail/>}></Route>
        </Route>
      </Route>
    )                    
  ); 
  
  function App() { 
    
    return (
      <div className="font-bodyFont">
        <WhatsappMenu/>
         {/* <FloatingWhatsApp phoneNumber='+994515111015' accountName="Selena Tour" statusMessage='' chatMessage='Salam, sizə necə kömək edə bilərik?' placeholder='Mesaj yazın...' avatar='./avatar.png' messageDelay='1' /> */}
        <RouterProvider router={router} />
      </div>
    );
  }
  
  export default App;