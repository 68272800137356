import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { loading } from '../../../assets/images';
import { translateText } from "../../../translate";

const Tours = () => {
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [tours, setTours] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeButton, setActiveButton] = useState("Qaynar Turlar");
  const [language, setLanguage] = useState(localStorage.getItem("language") || "az");

  const [translatedNames, setTranslatedNames] = useState({}); // Çeviri sonuçlarını saklayan state

  const buttons = useMemo(() => [
    { key: "Qaynar Turlar", label: "Qaynar Turlar", category1: "qaynar" },
    { key: "Ölkədaxili turlar", label: "Ölkədaxili turlar", category1: "olkedaxili" },
    { key: "Beynəlxalq turlar", label: "Beynəlxalq turlar", category1: "beynelxalq" },
  ], []);

  const { t } = useTranslation();

  useEffect(() => { 
    const getData = async () => {
      try {
        const response = await axios.get("https://www.selenatour.az/selena/tours.php", { withCredentials: false });
        setTours(response.data.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Veri alınırken hata oluştu:", error); 
        setIsLoading(false);
      }
    }; 
    getData();
  }, []);

  useEffect(() => {
    if (activeButton) {
      const selectedCategory1 = buttons.find((btn) => btn.key === activeButton)?.category1;
      if (selectedCategory1) {
        setFilteredBlogs(tours.filter((tour) => tour.category1 === selectedCategory1).slice(0, 4));
      } else {
        setFilteredBlogs(tours.slice(0, 3));
      }
    }
  }, [activeButton, tours, buttons]);

  useEffect(() => {
    const translateAllNames = async () => {
      const translations = {};
      for (const tour of filteredBlogs) {
        try {
          translations[tour.id] = await translateText(tour.name, language);
        } catch (error) {
          console.error("Çeviri hatası:", error);
          translations[tour.id] = tour.name; // Hata durumunda orijinal metni kullan
        }
      }
      setTranslatedNames(translations);
    };
 
    if (filteredBlogs.length > 0) {
      translateAllNames();
    }
  }, [language, filteredBlogs]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <img src={loading} alt="Loading..." className="w-[300px] h-[300px] md:w-[350px] md:h-[350px]" />
      </div>
    );
  }

  return (
    <div className="mt-[43px] md:mt-[68px] md:max-w-container max-w-containerSm mx-auto">
      <div className='flex flex-col justify-center items-center'>
        <p className="text-black text-[24px] md:text-[28px] font-medium text-center font-titleFont">{t("Turlar")}</p>
      </div>

      <div className="flex md:justify-center mt-4 gap-4 overflow-x-auto whitespace-nowrap scrollbar-hide">
        {buttons.map((button) => (
          <button
            key={button.key}
            onClick={() => setActiveButton(button.key)}
            className={`h-[36px] rounded-[20px] px-4 mt-2 md:mt-0 transition-all duration-300 ease-in-out ${
              activeButton === button.key
                ? "text-[black] bg-[#FEC919]"
                : "text-[black] bg-[white] border-2 border-[#FEC919] hover:bg-[#FEC919] hover:text-[black]"
            }`}
          >
            {t(button.label)}
          </button>
        ))}
      </div>

      <div>
        <div className='grid md:grid-cols-4 grid-cols-1 gap-5 mt-6 md:mt-[48px]'>
          {filteredBlogs.map((val) => {
            const { id, image, name, date1, date2, price, price2, exchange } = val;

            return (
              <div className="bg-[white] rounded-[16px] border-[1px] border-[#E5E5E5]" key={id}>
                <div>
                  <div className="w-full  md:h-[375px] rounded-[16px]">
                    <img
                      className="md:w-[305px] md:h-[375px] w-full h-auto rounded-[16px]"
                      src={`https://www.selenatour.az/selena/${image}`}
                      alt={name}
                    />
                  </div>
                  <div className="pt-[16px] px-[13px] pb-[24px] text-[#0E0E0E]">
                    <h4 className="font-medium font-titleFont">{translatedNames[id] || name}</h4>
                    <h4 className="font-medium font-titleFont">{t(date1)} - {t(date2)}</h4>
                    <button className="mt-[24px] w-full rounded-[19px] bg-[#FEC919] h-[32px] transition duration-300 ease-in-out block">
                      {t(price2 > 0 ? `${price} ${exchange} - ${price2} ${exchange}` : `${price} ${exchange}-${t('dən başlayaraq')}`)}
                    </button>
                    <a href={`/tours/${id}`}>
                      <button className="mt-[17px] font-titleFont w-full rounded-[33px] border border-[#FEC919] h-[44px] hover:bg-[#FEC919] transition duration-300 ease-in-out">
                        {t("Ətraflı")}
                      </button>
                    </a> 
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Tours;
