import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { about1, about2, about3, about4, aboutIcon1, aboutIcon2, aboutIcon3, hero2 } from '../../../assets/images';


const About = () => {

  const [blogs, setBlogs] = useState([]);
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeButton, setActiveButton] = useState("Sərgilər");
  const buttons = [
    { key: "Sərgilər", label: "Sərgilər", type: "sergi" },
    { key: "Təlimlər", label: "Təlimlər", type: "telim" },
  ];

  const { t, i18n } = useTranslation();

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    // Filter blogs based on the active button
    if (activeButton) {
      const selectedType = buttons.find((btn) => btn.key === activeButton)?.type;
      if (selectedType) {
        setFilteredBlogs(blogs.filter((blog) => blog.type === selectedType).slice(0, 3));
      } else {
        setFilteredBlogs(blogs.slice(0, 3)); // Show only the first 3 if no type is matched
      }
    }
  }, [activeButton, blogs]);
  
  const getProducts = async () => {
    try {
      const response = await axios.get(
        `https://www.agrogurama.az/agrogurama/blogs.php`,
        { withCredentials: false }
      );
      const fetchedBlogs = response.data.data;
      setBlogs(fetchedBlogs);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>; // Show loading indicator while data is being fetched
  }

  return (
     <div className="md:mt-[120px] md:max-w-container max-w-containerSm mx-auto md:flex justify-between gap-[32px] mt-[72px]">
      <div className='md:w-[615px] pt-[20px] md:flex gap-[20px] relative'> 
     <div>
   <div className='flex justify-end'> <img className='h-[233px]' src={about1}></img></div>
    <img className='h-[203px] mt-[20px]' src={about2}></img>
     </div>
     <div className='pt-[33px]'>
     <div className='flex justify-end md:block'><img className='h-[203px]' src={about3}></img></div>
     <img className='h-[236px] mt-[20px]' src={about4}></img>
     </div>
     <div className='absolute bottom-16 right-0 text-[#0E0E0E] bg-white bg-opacity-80 text-center p-3 rounded-lg border-2 border-[#FEC919] h-[120px] w-[182px] py-[12px] px-[20px]'>
     {t("2016-dan etibarən etibarlı səyahət yoldaşınız!")}
  </div>
      </div>
      <div className='md:w-[633px] mt-[32px] md:mt-0'>
        <div>
            <div className='flex gap-2 items-center'>
            <p className='text-[#FEC919]'>{t("Haqqımızda")}</p>
            <div className='w-[43px] h-[1px] bg-[#FEC919]'></div>
            </div> 
            <p className='text-[24px] md:text-[28px] font-medium text-[#0E0E0E] md:w-[419px] font-titleFont'>{t("Səyahətiniz Bizimlə Daha Rahat!")}</p>
            <p className='mt-[24px] text-[#696767]'>{t("2016-cı ildən bəri unudulmaz səyahətlər təşkil edirik. Fərdi və qrup turlarından tutmuş, viza dəstəyi və transfer xidmətlərinə qədər hər detayı sizin üçün düşünürük. Rahat, təhlükəsiz və maraqlı turlarımızla dünyanı birlikdə kəşf edək!")}</p>
            <div className='flex rounded-[8px] gap-[10px] border-2 border-[#E5E5E5] w-full md:w-[361px] min-h-[51px] mt-6 px-[19px] py-[12px] font-titleFont'>
                <img  className='h-6 w-6' src={aboutIcon1}></img>
                <p className='text-[16px] md:text-[18px] font-medium'>{t("Minlərlə məmnun səyahətçi")}</p>
            </div>
            <div className='flex rounded-[8px] gap-[10px] border-2 border-[#E5E5E5] items-center w-full md:w-[403px] min-h-[72px] md:h-[51px] mt-6 px-[19px] py-[12px] font-titleFont'>
                <img  className='h-6 w-6' src={aboutIcon2}></img>
                <p className=' text-[16px] md:text-[18px] font-medium'>{t("Dünyanın hər yerinə unudulmaz turlar")}</p>
            </div>
            <div className='flex rounded-[8px] gap-[10px] border-2 border-[#E5E5E5] w-full md:w-[368px] min-h-[51px] mt-6 px-[19px] py-[12px] font-titleFont'>
                <img  className='h-6 w-6' src={aboutIcon3}></img>
                <p className='md:text-[18px] text-[16px] font-medium'>{t("Səyahətiniz üçün 8+ illik təcrübə")}</p>
            </div>
            <a href='/about'><button className='h-[44px] w-full md:w-[244px] mt-[32px] bg-[#FEC919] hover:bg-[#F59A2C] text-white rounded-[33px] font-titleFont'>{t("Daha ətraflı")}</button></a>
        </div>
      </div>
    </div>
  );
};

export default About;
