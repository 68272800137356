import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { about1, about2, about3, about4, countries1, countries2, countries3, countries4, hero2, heroBg, locations1, locations2, locations3, locations4, services1, services2, services3, services4, services5, services6, services7, services8 } from '../../../assets/images';


const Services = () => {

  const [blogs, setBlogs] = useState([]);
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeButton, setActiveButton] = useState("Sərgilər");
  const buttons = [
    { key: "Sərgilər", label: "Sərgilər", type: "sergi" },
    { key: "Təlimlər", label: "Təlimlər", type: "telim" },
  ];

  const { t, i18n } = useTranslation();

  useEffect(() => {
    getProducts(); 
  }, []);

  useEffect(() => {
    // Filter blogs based on the active button
    if (activeButton) {
      const selectedType = buttons.find((btn) => btn.key === activeButton)?.type;
      if (selectedType) {
        setFilteredBlogs(blogs.filter((blog) => blog.type === selectedType).slice(0, 3));
      } else {
        setFilteredBlogs(blogs.slice(0, 3)); // Show only the first 3 if no type is matched
      }
    }
  }, [activeButton, blogs]);
  
  const getProducts = async () => {
    try {
      const response = await axios.get(
        `https://www.agrogurama.az/agrogurama/blogs.php`,
        { withCredentials: false }
      );
      const fetchedBlogs = response.data.data;
      setBlogs(fetchedBlogs);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>; // Show loading indicator while data is being fetched
  }

  return (
     <div className="mt-[120px] md:max-w-container max-w-containerSm mx-auto ">
      <div className='flex justify-between'>
     <div> 
     <div className='flex gap-2 items-center'>
            <p className='text-[#FEC919]'>{t("Xidmətlər")}</p>
            <div className='w-[43px] h-[1px] bg-[#FEC919]'></div>
            </div> 
            <p className='text-[24px] md:text-[28px] font-medium text-[#0E0E0E] md:w-[419px] font-titleFont'>{t("Yüksək keyfiyyətli xidmətlər təqdim edirik")}</p>
     </div>
     <div className='md:block hidden'>
     <a href='/services'><button className='h-[44px] w-[244px] mt-[32px] bg-[#FEC919] hover:bg-[#F59A2C] text-white rounded-[33px] font-titleFont'>{t("Ətraflı bax")}</button></a>
     </div>
      </div>
      <div className='md:mt-[56px] mt-[32px] grid grid-cols-2 md:grid-cols-8 gap-[20px] md:gap-0'>
   <div>
   <img className='mx-auto w-[46px] h-[46px]' src={services1}></img>
   <p className='mt-[5px] text-center'>{t("Tur Paketlərinin Təşkili")}</p>
   </div>
   <div>
   <img className='mx-auto w-[46px] h-[46px]' src={services2}></img>
   <p className='mt-[5px] text-center'>{t("Viza dəstəyi")}</p>
   </div>
   <div>
   <img className='mx-auto w-[46px] h-[46px]' src={services3}></img>
   <p className='mt-[5px] text-center'> {t("Aviabiletlərin Satışı")}</p>
   </div>
   <div>
   <img className='mx-auto w-[46px] h-[46px]' src={services4}></img>
   <p className='mt-[5px] text-center'>{t("Otel və Apartament Rezervasiyası")}</p>
   </div> 
   <div> 
   <img className='mx-auto w-[46px] h-[46px]' src={services5}></img>
   <p className='mt-[5px] text-center'>{t("MICE Xidmətləri (Biznes Turizm)")}</p>
   </div>
   <div>
   <img className='mx-auto w-[46px] h-[46px]' src={services6}></img>
   <p className='mt-[5px] text-center'>	{t("Müalicəvi və sağlamlıq turları")}</p>
   </div>
   <div>
   <img className='mx-auto w-[46px] h-[46px]' src={services7}></img>
   <p className='mt-[5px] text-center'>{t("Avtomobil Kirayəsi və Transferlər")}</p>
   </div>
   <div>
   <img className='mx-auto w-[46px] h-[46px]' src={services8}></img>
   <p className='mt-[5px] text-center'>{t("Sığorta")}</p>
   </div>
      </div>
      <a href='/contact'><button className='h-[44px] md:hidden w-full mt-[32px] text-white bg-[#FEC919] hover:bg-[#F59A2C] font-titleFont rounded-[33px]'>{t("Müraciət edin")}</button></a>
      <div>
        
      </div>
    </div>
  );
};

export default Services;
