import React, { useEffect, useState } from 'react'
import { about1, about2, about3, about4, about5, aboutBg, avia, blogLeft, blogRightBottom, blogRightTop, contactBg, contactBg2, contactBg3, footerIcon1, footerIcon10, footerIcon11, footerIcon12, footerIcon13, footerIcon14, footerIcon2, footerIcon3, footerIcon4, footerIcon5, footerIcon6, footerIcon7, footerIcon8, footerIcon9, hero2, logo, productBg, services1, services2, services3, services4, services5, services6, services7, services8, servicesBg, whyus } from '../../assets/images';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FiMenu, FiX } from 'react-icons/fi';
import GeneralHeader from '../../Components/home/GeneralHeader/GeneralHeader';
import axios from 'axios';




const Contact = () => {
 
    const { t, i18n } = useTranslation();

    const [tours, setTours] = useState([]);
 
    const fetchTours = async () => {
      try {
        const response = await axios.get('https://www.selenatour.az/selena/settings.php');
        setTours(response.data[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    useEffect(() => {
      fetchTours();
    }, []);

  return (
   <>
   <div style={{height: window.innerWidth < 640 ? '246px' : '246px', backgroundImage: `url(${aboutBg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
     <GeneralHeader/>
     
     <div className='text-center pt-10'>
       <p className='text-[24px] md:text-[32px] font-medium text-white font-titleFont'>{t("Əlaqə")}</p>
       <div className='flex gap-2 justify-center text-white mt-3'>
       <p className='text-[14px] md:text-[16px]'><a href='/'>{t("Ana səhifə")}</a></p>
       <img className='w-6 h-6' src={footerIcon7}></img>
       <p className='text-[14px] md:text-[16px]'>{t("Əlaqə")}</p> 
       </div>
     </div> 
     
   </div>
   <div className="mt-[57px] md:max-w-container max-w-containerSm mx-auto  ">
   <div className='md:flex justify-between'>
    <div className='md:w-[224px] '>
 <div>
          <div className="space-y-4">
            <div>
            <div className='gap-2 flex'>
              <img className='h-6 w-6' src={footerIcon8}></img>
              <p className='text-[#FEC919] text-[18px] font-medium font-titleFont'>{t("Ünvan")}</p>
            </div>
            <p className='mt-2'>{t(tours.location)}</p>
            </div>
            <div>
            <div className='gap-2 flex mt-4'>
              <img className='h-6 w-6' src={footerIcon9}></img>
              <p className='text-[#FEC919] text-[18px] font-medium font-titleFont'>{t("E-poçt")}</p>
            </div>
            <p className='mt-2'>{tours.mail}</p>
            </div>
            <div>
            <div className='gap-2 flex mt-4'>
              <img className='h-6 w-6' src={footerIcon10}></img>
              <p className='text-[#FEC919] text-[18px] font-medium font-titleFont'>{t("Əlaqə nömrəsi")}</p>
            </div>
            <p className='mt-2'>{tours.phone1}</p>
            <p className='mt-2'>{tours.phone2}</p>
            <p className='mt-2'>{tours.phone3}</p>
            <p className='mt-2'>{tours.phone4}</p>
            </div>
             <div className='flex gap-3 mt-4'>
                        <a href={`${tours.whatsapp}`}><img className='w-[44px] h-[44px]' src={footerIcon11}></img></a>
                        <a href={`${tours.instagram}`}><img className='w-[44px] h-[44px]' src={footerIcon12}></img></a>
                        <a href={`${tours.facebook}`}><img className='w-[44px] h-[44px]' src={footerIcon13}></img></a>
                        <a href={`${tours.tiktok}`}><img className='w-[44px] h-[44px]' src={footerIcon14}></img></a>
                      </div>
</div>
        </div>
    </div>
    <div className='md:w-[966px] mt-[56px] md:mt-0'>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d194473.18588939894!2d49.8549596!3d40.394592499999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307d6bd6211cf9%3A0x343f6b5e7ae56c6b!2zQmFrxLE!5e0!3m2!1saz!2saz!4v1739964667403!5m2!1saz!2saz" style={{width: window.innerWidth < 640 ? '100%' : '966px'}} height="389" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
   </div>
         <div>
           
         </div>
       </div>
        <div className="mt-[64px] md:max-w-container max-w-containerSm mx-auto font-titleFont">
               <div
           style={{
             backgroundImage: window.innerWidth < 640 ? `url(${contactBg3})` : `url(${contactBg2})`,
             backgroundSize: 'cover', 
             backgroundPosition: 'center',
             height: '268px',
             display: 'flex',
             flexDirection: 'column',
             justifyContent: 'center', // Dikeyde ortalar
             alignItems: 'center', // Yatayda ortalar
             textAlign: 'center', 
             borderRadius:'36px' 
           }}
         >
           <p className='text-center text-white text-[18px] md:text-[24px] font-medium md:w-[649px]'>
             {t("Əgər siz də bizim dinamik və uğurlu komandamızın bir parçası olmaq istəyirsinizsə bizimlə əlaqə saxlayın!")}
           </p>
           <a href='/contact'><button className='h-[44px] w-[206px] mt-[16px] md:mt-[32px] bg-[white] text-[#0E0E0E] rounded-[33px]'>{t("Ətraflı bax")}</button></a>
         </div>
           </div>
       </>
  )
} 

export default Contact



























