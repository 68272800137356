import { useState } from 'react';
import { whatsapp } from "../assets/images";
import { AnimatePresence, motion } from 'framer-motion'; // animasyon için
import { useTranslation } from 'react-i18next';

const WhatsAppMenu = () => {

    const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);

  const options = [
    { label: 'Tur', phone: '+994774171015' },
    { label: 'Bilet', phone: '+994515111015' },
    { label: 'Viza dəstəyi', phone: '+994774751015' },
  ];

  const openWhatsApp = (phone) => {
    window.open(`https://wa.me/${phone}`, '_blank');
    setOpen(false);
  };

  return (
    <div className="fixed bottom-8 right-8 z-50 flex flex-col items-end space-y-2">
      <AnimatePresence>
        {open && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.3 }}
            className="bg-white rounded-2xl shadow-lg p-4 space-y-2 w-52"
          >
            {options.map((opt) => (
              <button
                key={opt.phone}
                onClick={() => openWhatsApp(opt.phone)}
                className="w-full text-left text-sm text-gray-800 hover:bg-gray-100 px-4 py-2 rounded-lg transition-all duration-200"
              >
                {t(opt.label)}
              </button>
            ))}
          </motion.div>
        )}
      </AnimatePresence>

      <button
        onClick={() => setOpen(!open)}
        className="bg-green-500 hover:bg-green-600 transition-all duration-200 text-white p-4 rounded-full shadow-xl flex items-center justify-center"
      >
        <img className="h-8 w-8" src={whatsapp} alt="WhatsApp" />
      </button>
    </div>
  );
};

export default WhatsAppMenu;
