import React, { useState } from 'react'
import { logo, search,  azFlag, cinFlag, engFlag, rusFlag, saFlag } from '../../../assets/images';
import { useLocation } from 'react-router-dom';
import { FiMenu, FiX } from 'react-icons/fi';
import enTranslation from "../../i18n/en/translation.json";
import azTranslation from "../../i18n/az/translation.json";
import rusTranslation from "../../i18n/rus/translation.json";
import arbTranslation from "../../i18n/arb/translation.json";
import cinTranslation from "../../i18n/cin/translation.json";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";


const languageOptions = [ 
  { value: "en", label: "EN", flag: engFlag },
  { value: "az", label: "AZ", flag: azFlag },
  { value: "ru", label: "RU", flag: rusFlag },
  { value: "zh-CN", label: "CIN", flag: cinFlag },
  { value: "ar", label: "SA", flag: saFlag },
];

i18n.use(initReactI18next).init({
  resources: {
      en: { translation: enTranslation }, 
      az: { translation: azTranslation },
      ru: { translation: rusTranslation },
      zh: { translation: cinTranslation },
      ar: { translation: arbTranslation },
  },
  lng: localStorage.getItem("language") || "az",
  fallbackLng: "az",
  interpolation: { escapeValue: false },
});

const GeneralHeader = () => {

        const { t, i18n } = useTranslation();
            const location = useLocation();  

              const [isOpen, setIsOpen] = useState(false);
                const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false)
                const [selectedLanguage, setSelectedLanguage] = useState(languageOptions.find(lang => lang.value === i18n.language));

            const isActiveLink = (path) => {
              if (path === '/') {
                return location.pathname === '/';
              }
              return location.pathname.startsWith(path);
            };

            const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

            const handleLanguageChange = (language) => {
              setSelectedLanguage(language);
              setIsOpen(false);
              i18n.changeLanguage(language.value);
              localStorage.setItem("language", language.value);
              window.location.reload()
          };

          const toggleHamburgerMenu = () => setIsHamburgerMenuOpen(!isHamburgerMenuOpen);

            const toggleDropdown = () => {
              setIsOpen(!isOpen);
            };


  return (
    <div className='h-[80px] pt-5 relative z-20 text-white'>
             <div className='md:max-w-container max-w-containerSm mx-auto h-[45px] flex justify-between'>
               <div>
                 <a href='/'><img alt='logo' height={45} width={45} src={logo} /></a>
               </div> 
               <div className='flex justify-center items-center'>
                 <nav className='hidden md:flex'>
                   <ul className="flex space-x-11">
                     <a href="/">
                       <li >{t("Ana səhifə")}</li>
                     </a>
                     <a href="/about">
                       <li className={isActiveLink('/about') ? 'text-[#FEC919]' : ''}>{t("Haqqımızda")}</li>
                     </a>
                     <a href="/services">
                       <li className={isActiveLink('/services') ? 'text-[#FEC919]' : ''}>{t("Xidmətlər")}</li>
                     </a>
                     <a href="/tours">
                       <li className={isActiveLink('/tours') ? 'text-[#FEC919]' : ''}>{t("Turlar")}</li>
                     </a>
                     <a href="/avia">
                       <li className={isActiveLink('/avia') ? 'text-[#FEC919]' : ''}>{t("Aviabiletlər")}</li>
                     </a>
                     <a href="/contact">
                       <li className={isActiveLink('/contact') ? 'text-[#FEC919]' : ''}>{t("Əlaqə")}</li>
                     </a>
                   </ul>
                      <div className="ml-16 mr-6"><img className="h-6 w-6" src={search}></img></div>
                       <div className="relative inline-flex items-center space-x-4 mt-4 sm:mt-0 sm:ml-0 flex-wrap sm:flex-nowrap">
                      
                      <button
                        onClick={toggleDropdown}
                        className="flex items-center space-x-2 px-2 py-2rounded-full"
                      >
                        <img className='w-6 h-6' src={selectedLanguage.flag}></img>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.69222 7.09327C3.91686 6.83119 4.31143 6.80084 4.5735 7.02548L10.0001 11.6768L15.4267 7.02548C15.6888 6.80084 16.0833 6.83119 16.308 7.09327C16.5326 7.35535 16.5022 7.74991 16.2402 7.97455L10.4068 12.9745C10.1728 13.1752 9.8274 13.1752 9.59335 12.9745L3.76001 7.97455C3.49794 7.74991 3.46759 7.35535 3.69222 7.09327Z" fill="white"/>
                      </svg>
                      </button>
                      <button className="block md:hidden focus:outline-none" onClick={toggleHamburgerMenu}>
                      <i className="fa-solid fa-bars text-[24px]"></i>
                      {isHamburgerMenuOpen && (
                      <div className={`fixed inset-y-0 left-0 transform ${isHamburgerMenuOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out bg-white w-full z-50 shadow-lg`}>
                           <div className="flex">
                           <div className="absolute top-8 right-20">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5 2.75C6.66751 2.75 2.75 6.66751 2.75 11.5C2.75 16.3325 6.66751 20.25 11.5 20.25C16.3325 20.25 20.25 16.3325 20.25 11.5C20.25 6.66751 16.3325 2.75 11.5 2.75ZM1.25 11.5C1.25 5.83908 5.83908 1.25 11.5 1.25C17.1609 1.25 21.75 5.83908 21.75 11.5C21.75 14.0605 20.8111 16.4017 19.2589 18.1982L22.5303 21.4697C22.8232 21.7626 22.8232 22.2374 22.5303 22.5303C22.2374 22.8232 21.7626 22.8232 21.4697 22.5303L18.1982 19.2589C16.4017 20.8111 14.0605 21.75 11.5 21.75C5.83908 21.75 1.25 17.1609 1.25 11.5Z" fill="black"/>
                      </svg>
                      </div>
                      <button className="absolute top-5 right-5 p-2 rounded" onClick={toggleHamburgerMenu}>
                      <i className="fa-solid fa-xmark text-[32px]"></i>
                      </button>
                      
                           </div>
                      <div className="flex flex-col items-start p-8 w-full ">
                      <div className="items-center justify-center sm:justify-end space-x-2 space-y-2 sm:space-y-0 h-screen">
                      <div className="gap-4 max-w-containerSm flex flex-col"> 
                      <a href='/'> <img src={logo} alt="Logo" className="h-[90px]" /></a>
                      <a className={`text-base text-left font-normal ${isActiveLink('/') ? 'text-[#0D4B2C] ' : 'text-[black]'}`} href="/"> {t("Ana səhifə")}</a>
                        <a href="/why-us" className={`text-base text-left font-normal ${isActiveLink('/why-us') ? 'text-[#0D4B2C]' : 'text-[black]'}`}>{t("Niyə biz?")}</a>
                        <a href="/services" className={`text-base text-left font-normal ${isActiveLink('/services') ? 'text-[#0D4B2C]' : 'text-[black]'}`}>{t("Xidmətlərimiz")}</a>
                        <a href="/care" className={`text-base text-left  font-normal ${isActiveLink('/care') ? 'text-[#0D4B2C]' : 'text-[black]'}`}>{t("Müalicə və prosedurlar")}</a>
                        <a href="/faq" className={`text-base text-left font-normal ${isActiveLink('/faq') ? 'text-[#0D4B2C]' : 'text-[black]'}`}>{t("FAQ")}</a>
                        <a href="/blogs" className={`text-base text-left font-normal ${isActiveLink('/blogs') ? 'text-[#0D4B2C]' : 'text-[black]'}`}>{t("Bloq")}</a>
                        <a href="/contact" className={`text-base text-left font-normal ${isActiveLink('/contact') ? 'text-[#0D4B2C]' : 'text-[black]'}`}>{t("Əlaqə")}</a>
                      </div>
                      
                      </div>
                      </div>
                      </div>
                      )} 
                      </button>
                      {isOpen && (
                        <div className="absolute right-0 top-10 mt-2 w-16 rounded-md shadow-lg bg-[white] ring-1 ring-black ring-opacity-5 z-50 border-[1px] border-black">
                          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                            {languageOptions.map((language) => (
                              <button
                                key={language.value}
                                onClick={() => handleLanguageChange(language)}
                                className="flex items-center space-x-2 px-4 text-[#b7b7b7] py-2 w-full text-left hover:bg-[#EDEDED]"
                              >
                                <img className='w-6 h-6' src={language.flag}></img>
                              </button>
                            ))}
                          </div>
                        </div>
                      )}
                      </div>
                 </nav>
                 <nav className='flex items-center md:hidden'>
                  <div className='mr-5' ><img className="h-6 w-6" src={search}></img></div>
                  <div className="relative inline-flex items-center space-x-4 mt-4 sm:mt-0 sm:ml-0 flex-wrap sm:flex-nowrap">
                  
                  <button
                    onClick={toggleDropdown}
                    className="flex items-center space-x-2 px-2 py-2rounded-full"
                  >
                    <img className='w-6 h-6' src={selectedLanguage.flag}></img>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.69222 7.09327C3.91686 6.83119 4.31143 6.80084 4.5735 7.02548L10.0001 11.6768L15.4267 7.02548C15.6888 6.80084 16.0833 6.83119 16.308 7.09327C16.5326 7.35535 16.5022 7.74991 16.2402 7.97455L10.4068 12.9745C10.1728 13.1752 9.8274 13.1752 9.59335 12.9745L3.76001 7.97455C3.49794 7.74991 3.46759 7.35535 3.69222 7.09327Z" fill="white"/>
                  </svg>
                  </button>
                  {isOpen && (
                    <div className="absolute right-0 top-10 mt-2 w-16 rounded-md shadow-lg bg-[white] ring-1 ring-black ring-opacity-5 z-50 border-[1px] border-black">
                      <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        {languageOptions.map((language) => (
                          <button
                            key={language.value}
                            onClick={() => handleLanguageChange(language)}
                            className="flex items-center space-x-2 px-4 text-[#b7b7b7] py-2 w-full text-left hover:bg-[#EDEDED]"
                          >
                            <img className='w-6 h-6' src={language.flag}></img>
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                  </div>
                 <button
                 className="lg:hidden text-gray-700 ml-5"
                 onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
               >
                 {isMobileMenuOpen ? <FiX size={28} color="#fff" /> : <FiMenu size={24} color="#fff" />}
               </button>
               <div
                       className={`fixed inset-0 bg-white text-black z-50 transform ${
                         isMobileMenuOpen ? "translate-x-0" : "translate-x-full"
                       } transition-transform duration-300 ease-in-out`}
                     >
                       <div className="max-w-containerSm mx-auto pt-8 flex flex-col h-full">
                         {/* Kapatma Butonu */}
                         <div className="flex justify-between">
                           <img className="h-[59px]" src={logo}></img>
                           <button onClick={() => setIsMobileMenuOpen(false)}>
                             <FiX size={32} />
                           </button>
                         </div>
               
                         {/* Menü İçeriği */}
                         <nav className="mt-10">
                           {[
                             { path: "/", label: "Ana səhifə" },
                             { path: "/about", label: "Haqqımızda" },
                             { path: "/services", label: "Xidmətlər" },
                             { path: "/tours", label: "Turlar" },
                             { path: "/avia", label: "Aviabiletlər" },
                             { path: "/contact", label: "Əlaqə" },
                           ].map((item, index) => (
                             <a
                             key={index}
                             href={item.path}
                             className={`block text-lg py-3 rounded-lg transition ${
                               isActiveLink(item.path) ? "text-yellow-500 font-medium" : "text-black"
                             }`}
                           >
                             {t(item.label)}
                           </a>
                           ))}
                         </nav>
                       </div>
                     </div>
                 </nav>
               </div>
             </div>
           </div>
  )
}

export default GeneralHeader