import React, { useState } from 'react'
import { about1, about2, about3, about4, about5, aboutBg, aboutIcon1, aboutIcon2, aboutIcon3, blogLeft, blogRightBottom, blogRightTop, footerIcon7, hero2, logo, productBg, servicesBg, whyus } from '../../assets/images';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FiMenu, FiX } from 'react-icons/fi';
import GeneralHeader from '../../Components/home/GeneralHeader/GeneralHeader';

const About = () => {

    const { t, i18n } = useTranslation();

  return (
   <>
   <div style={{height: window.innerWidth < 640 ? '246px' : '246px', backgroundImage: `url(${aboutBg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
     <GeneralHeader/>
     
     <div className='text-center pt-10'>
       <p className='text-[24px] md:text-[32px] font-medium text-white font-titleFont'>{t("Haqqımızda")}</p>
       <div className='flex gap-2 justify-center text-white mt-3'>
       <p className='text-[14px] md:text-[16px]'><a href='/'>{t("Ana səhifə")}</a></p>
       <img className='w-6 h-6' src={footerIcon7}></img>
       <p className='text-[14px] md:text-[16px]'>{t("Haqqımızda")}</p>
       </div>
     </div>
     
   </div>
   <div className="mt-[64px] md:mt-[77px] md:max-w-container max-w-containerSm mx-auto md:flex justify-between gap-[32px]">
         <div className='md:w-[615px] pt-[20px] md:flex gap-[20px] relative'>
        <div>
      <div className='flex justify-end'> <img className='h-[233px]' src={about1}></img></div>
       <img className='h-[203px] mt-[20px]' src={about2}></img>
        </div>
        <div className='pt-[33px]'>
        <div className='flex md:block justify-end'><img className='h-[203px]' src={about3}></img></div>
        <img className='h-[236px] mt-[20px]' src={about4}></img>
        </div>
        <div className='absolute bottom-16 right-0 text-[#0E0E0E] bg-white bg-opacity-80 text-center p-3 rounded-lg border-2 border-[#FEC919] h-[120px] w-[182px] py-[12px] px-[20px]'>
        {t("2016-dan etibarən etibarlı səyahət yoldaşınız!")}
     </div>
         </div>
         <div className='md:w-[633px] mt-8 md:mt-0'>
           <div>
               <div className='flex gap-2 items-center'>
               <p className='text-[#FEC919] font-titleFont'>{t("Haqqımızda")}</p>
               <div className='w-[43px] h-[1px] bg-[#FEC919]'></div>
               </div> 
               <p className='text-[24px] md:text-[28px] font-medium text-[#0E0E0E] md:w-[419px] font-titleFont'>{t("Səyahətiniz Bizimlə Daha Rahat!")}</p>
               <p className='mt-[24px] text-[#696767]'>{t("2016-cı ildən bəri unudulmaz səyahətlər təşkil edirik. Fərdi və qrup turlarından tutmuş, viza dəstəyi və transfer xidmətlərinə qədər hər detayı sizin üçün düşünürük. Rahat, təhlükəsiz və maraqlı turlarımızla dünyanı birlikdə kəşf edək!")}</p>
               <div className='flex rounded-[8px] gap-[10px] border-2 border-[#E5E5E5] md:w-[361px] min-h-[51px] mt-6 px-[19px] py-[12px] font-titleFont'>
                   <img  className='h-6 w-6' src={aboutIcon1}></img>
                   <p className='text-[18px] font-medium'>{t("Minlərlə məmnun səyahətçi")}</p>
               </div>
               <div className='flex rounded-[8px] gap-[10px] items-center border-2 border-[#E5E5E5] md:w-[403px] md:min-h-[51px] mt-6 px-[19px] py-[12px] font-titleFont'>
                   <img  className='h-6 w-6' src={aboutIcon2}></img>
                   <p className='text-[18px] font-medium'>{t("Dünyanın hər yerinə unudulmaz turlar")}</p>
               </div>
               <div className='flex rounded-[8px] gap-[10px] items-center border-2 border-[#E5E5E5] md:w-[368px] md:min-h-[51px] mt-6 px-[19px] py-[12px] font-titleFont'>
                   <img  className='h-6 w-6' src={aboutIcon3}></img>
                   <p className='text-[18px] font-medium'>{t("Səyahətiniz üçün 8+ illik təcrübə")}</p>
               </div>
               <a href='/about'><button className='h-[44px] w-full md:w-[244px] mt-[32px] bg-[#FEC919] hover:bg-[#F59A2C] text-white rounded-[33px] font-titleFont'>{t("Daha ətraflı")}</button></a>
           </div>
         </div>
       </div>
       <div className='mt-[72px] md:mt-[98px] md:max-w-container max-w-containerSm mx-auto md:flex gap-5'>
    <div className='md:w-[738px]'>
<p className='text-[28px] font-medium font-titleFont'>{t("Xəyalınızdakı Səyahət Bizimlə Gerçəyə Çevrilir!")}</p>
<p className='text-[#696767] mt-6'>{t("Missiyamız səyahətçiləri dünyanın ən möhtəşəm yerləri ilə birləşdirmək, onlara peşəkar bələdçilik, xüsusi marşrutlar və mükəmməl xidmət təqdim etməkdir. İstər dincəlmək, istər macəra, istərsə də mədəniyyətlə dolu bir səyahət arzulayın, biz hər səfəri diqqətlə və dəqiqliklə planlaşdırırıq.")}  <br></br> <br></br> {t("İllərin təcrübəsi və fədakar komandamızla müştəri məmnuniyyəti, şəffaflıq və innovasiyanı əsas prinsiplərimiz kimi görürük.Səyahət arzularınızı gerçəkləşdirmək üçün bizimlə səyahət edin!")}</p>
    </div>
    <div className='md:w-[522px] mt-5 md:mt-0'>
      <img className='w-full md:h-[294px]' src={about5}></img>
    </div>
       </div>
       </>
  )
}

export default About