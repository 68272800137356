import React, { useEffect, useState } from 'react'
import { city1, city2, city3, city4, hero2, icons1, icons2, icons3, icons4 } from '../../../assets/images';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const Hero = () => {
  const { t, i18n } = useTranslation();

  const [tours, setTours] = useState([]);
 
  const fetchTours = async () => {
    try {
      const response = await axios.get('https://www.selenatour.az/selena/settings.php');
      setTours(response.data[0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchTours();
  }, []);

  const [activeIndex, setActiveIndex] = useState(0);

  const cities = [
    { id: 1, image: city1, title: "Moskva", text: "Moskva – tarixi irsi, möhtəşəm memarlığı və zəngin mədəniyyəti ilə unudulmaz səyahət təcrübəsi təqdim edən şəhər." },
    { id: 2, image: city2, title: "İstanbul", text: "İstanbul – iki qitəni birləşdirən, tarixi və mədəni zənginliyi ilə heyran edən unikal şəhər." },
    { id: 3, image: city3, title: "Maldiv", text: "Maldiv – ağ qumlu çimərlikləri, şəffaf suları və lüks istirahət məkanları ilə tropik cənnət." },
    { id: 4, image: city4, title: "Avropa", text: "Avropa – tarixi şəhərləri, zəngin mədəniyyəti və fərqli memarlıq üslubları ilə unikal səyahət marşrutu." },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % 4);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
    <div className="mx-auto justify-between md:h-[700px] pb-[30px] md:pb-0 hidden md:flex">
      <div className="w-1/2 flex justify-end">
        <div className="w-[630px] pt-[60px]">
          <div className='bg-white h-[186px] w-[1px] ml-[14px]'>
          </div>
          <div className='py-[20px] flex flex-col gap-4 relative z-10'>
  <a href={`${tours.instagram}`}><img className='h-7 w-7' src={icons1}></img></a>
  <a href={`${tours.facebook}`}><img className='h-7 w-7' src={icons2}></img></a>
  <a href={`${tours.tiktok}`}><img className='h-7 w-7' src={icons3}></img></a>
  <a href={`${tours.whatsapp}`}><img className='h-7 w-7' src={icons4}></img></a>
</div>

          <div className='bg-white h-[186px] w-[1px] ml-[14px]'>
          </div>
        </div> 
      </div>
      <div className="flex flex-col justify-center mx-auto backdrop-blur-2xl w-1/2 pl-[80px]">
       <div className='w-[550px]'>
       <div className=" text-white">
            <p className="text-[60px] font-bold font-titleFont">{t(cities[activeIndex].title)}</p>
            <p>{t(cities[activeIndex].text)}</p>
            <a href='/contact'><button className='h-[44px] w-[244px] mt-[32px] bg-[#FEC919] hover:bg-[#F59A2C] rounded-[33px]'>{t("Müraciət et")}</button></a>
        </div>
        <div className="flex items-center gap-5 mt-[116px]">
          {cities.map(({ id, image, title, text }, index) => (
            <div
              key={id}
              className={`w-[137px] h-[157px] transition-all duration-500 ${activeIndex === index ? "scale-125" : "scale-100"}`}
            >
              <img src={image} alt={`city-${id}`} />
            </div>
          ))}
        </div>
       </div>
      </div>
    </div>

    <div className="mx-auto justify-between md:h-[700px] pb-[30px] md:pb-0 flex md:hidden max-w-containerSm">
    <div className="flex flex-col justify-center mx-auto">
     <div className='w-full'>
     <div className=" text-white">
          <p className="text-[44px] md:text-[60px] font-bold font-titleFont mt-10">{t(cities[activeIndex].title)}</p>
          <p className='max-w-containerSm'>{t(cities[activeIndex].text)}</p>
          <a href='/contact'><button className='h-[44px] w-[206px] mt-[32px] bg-[#FEC919] hover:bg-[#F59A2C] rounded-[33px]'>{t("Müraciət et")}</button></a>
      </div>
      <div className="flex items-center gap-5 mt-[125px]">
        {cities.map(({ id, image, title, text }, index) => (
          <div
            key={id}
            className={`w-[137px] h-[192px] md:h-[157px] transition-all duration-500 ${activeIndex === index ? "scale-125" : "scale-100"}`}
          >
            <img src={image} alt={`city-${id}`} />
          </div>
        ))}
      </div>
     </div>
    </div>
  </div>
    </>
  );
};

export default Hero;
