import React, { useEffect } from 'react';
import Hero from '../../Components/home/Hero/Hero';
import Services from '../../Components/home/Services/Services';
import Contact from '../../Components/home/Contact/Contact';
import Header from '../../Components/Header/Header';
import { heroVideo } from '../../assets/images';
import { useNavigate } from 'react-router-dom';
import Tours from '../../Components/home/Tours/Tours';
import About from '../../Components/home/About/About';
import Cities from '../../Components/home/Cities/Cities';
import LanguageSwitcher from '../../LanguageSwitcher';



const Home = () => {


  return (
    <> 
     

<div className='bg-black/30' style={{ position: 'relative', overflow: 'hidden' }}>
<video
  autoPlay
  loop
  muted
  playsInline
  style={{ 
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    zIndex: -1 
  }}
>
  <source src={heroVideo} type="video/mp4" />  
  Your browser does not support the video tag.
</video> 
 
<Header />
<Hero />
</div>
      <Tours />
      <About />
      <Cities/>
      <Services />
      <Contact /> 
    </>
  );
}

export default Home;














































