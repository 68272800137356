import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import GeneralHeader from "../../Components/home/GeneralHeader/GeneralHeader";
import { footerIcon7, footerIcon15, aboutBg, tourDetail, loading } from "../../assets/images";
import { useTranslation } from "react-i18next";
import { translateText } from "../../translate";

const Tours = () => {
  const [tours, setTours] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Initially set loading to true
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

   const fetchCategories = async () => {
      try {
        const response = await axios.get('https://www.selenatour.az/selena/categories.php');
        setCategories(response.data.data);
        console.log(response.data.data)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

      useEffect(() => {
        fetchCategories();
      }, []);
  

  const queryParams = new URLSearchParams(location.search);
  const categoryFromUrl = queryParams.get("category");
    const [language, setLanguage] = useState(localStorage.getItem("language") || "az");

  // Fetch tours data
  useEffect(() => {
    axios
      .get("https://www.selenatour.az/selena/tours.php", { withCredentials: false })
      .then((response) => {
        setTours(response.data.data);
        setIsLoading(false); // Set loading to false after data is fetched
      })
      .catch((error) => {
        console.error("Veri alınırken hata oluştu:", error);
        setIsLoading(false); // Set loading to false even on error
      });
  }, []);

  // Filter tours based on URL category
  const filteredTours = categoryFromUrl
    ? tours.filter((tour) => tour.category2 === categoryFromUrl)
    : tours;

  // Handle category button click to update URL
  const handleCategoryClick = (category) => {
    navigate(`/tours?category=${category}`);
  };

    const [translatedTexts, setTranslatedTexts] = useState({});
    const [translatedTitles, setTranslatedTitles] = useState({});

useEffect(() => {
  const translateTours = async () => {
    const newTranslations = {};
    for (const tour of categories) {
      try {
        newTranslations[tour.id] = await translateText(tour.name, language);
      } catch (error) {
        console.error("Çeviri hatası:", error);
        newTranslations[tour.id] = tour.name; // Hata olursa orijinal ismi kullan
      }
    }
    setTranslatedTitles(newTranslations);
  };

  if (categories.length > 0) {
    translateTours();
  }
}, [language, categories]);

useEffect(() => {
  const translateTours = async () => {
    const newTranslations = {};
    for (const tour of filteredTours) {
      try {
        newTranslations[tour.id] = await translateText(tour.name, language);
      } catch (error) {
        console.error("Çeviri hatası:", error);
        newTranslations[tour.id] = tour.name; // Hata olursa orijinal ismi kullan
      }
    }
    setTranslatedTexts(newTranslations);
  };

  if (filteredTours.length > 0) {
    translateTours();
  }
}, [language, filteredTours]);

  

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <img src={loading} alt="Loading..." className="w-[300px] h-[300px] md:w-[350px] md:h-[350px]" />
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          height: window.innerWidth < 640 ? "246px" : "246px",
          backgroundImage: `url(${aboutBg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <GeneralHeader />
        <div className="text-center pt-10">
          <p className="text-[24px] md:text-[32px] font-medium text-white font-titleFont">{t("Turlar")}</p>
          <div className="flex gap-2 justify-center text-white mt-3">
          <p className='text-[14px] md:text-[16px]'><a href='/'>{t("Ana səhifə")}</a></p>
            <img className="w-6 h-6" src={footerIcon7} alt="icon" />
            <p className="text-[14px] md:text-[16px]">{t("Turlar")}</p>
          </div>
        </div>
      </div>
 
      <div className="mt-[68px] md:max-w-container max-w-containerSm mx-auto">
        <div className="md:flex gap-10">
          <div className="md:w-[285px]">
            <p className="text-[24px] font-medium font-titleFont mb-10">{t("İstiqamətlər")}</p>
            {categories.map((button) => (
              <button
                key={button.id}
                onClick={() => handleCategoryClick(button.category2)}
                className={`border-2 hover:bg-[#FEC919] w-full border-[#FEC919] rounded-[4px] flex justify-between px-[25px] h-[54px] mt-5 items-center ${
                  categoryFromUrl === button.category2 ? "bg-[#FEC919]" : "bg-[white]"
                }`}
              >
                <p className="text-center text-[20px] font-medium font-titleFont">{t(translatedTitles[button.id] || button.name)}</p>
                <img className="w-[28px] h-[28px]" src={footerIcon15} alt="icon" />
              </button>
            ))}
          </div>

          <div className="mt-[72px] md:mt-0">
          <p className="text-[24px] font-medium font-titleFont">
  {categoryFromUrl
    ? translatedTitles[categories.find((btn) => btn.category2 === categoryFromUrl)?.id] || categories.find((btn) => btn.category2 === categoryFromUrl)?.name || t("Bütün Turlar")
    : t("Bütün Turlar")}
</p>


            <div className="grid md:grid-cols-3 grid-cols-1 md:gap-5 gap-4 mt-[40px] md:w-[935px]">
              {filteredTours.map((tour) => {
                const { id, image, name, date1, date2, price, price2, exchange } = tour;
                return (
                  <div key={id} className="bg-[white] rounded-[16px] border-[1px] border-[#E5E5E5]">
                    <div>
                      <div className="w-full md:h-[375px] rounded-[16px]">
                        <img
                          className="w-full md:h-[375px] h-auto rounded-[16px]"
                          src={`https://www.selenatour.az/selena/${image}`}
                          alt={name}
                        />
                      </div>
                      <div className="pt-[16px] px-[13px] pb-[24px] text-[#0E0E0E]">
                      <h4 className="font-medium font-titleFont">{t(translatedTexts[id] || name)}</h4>
                        <h4 className="font-medium font-titleFont">
                          {t(date1)} - {t(date2)}
                        </h4>
                        <button className="mt-[24px] w-full rounded-[19px] bg-[#FEC919] h-[32px] transition duration-300 ease-in-out block">
  {t(price2 > 0 ? `${price} ${exchange} - ${price2} ${exchange}` : `${price} ${exchange} - ${t('dən başlayaraq')}`)}
</button>

                        <a href={`/tours/${id}`}>
                          <button className="mt-[17px] font-titleFont w-full rounded-[33px] border border-[#FEC919] h-[44px] hover:bg-[#FEC919] transition duration-300 ease-in-out">
                            {t("Ətraflı")}
                          </button>
                        </a>
                      </div>
                    </div>
                  </div> 
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tours;

