import React, { useEffect, useState } from 'react';
import { footerIcon1, footerIcon16, footerIcon2, footerIcon3, footerIcon4, footerIcon5, footerIcon6, footerIcon7, logo, logoFooter } from '../../assets/images';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const Footer = () => {

  const { t, i18n } = useTranslation();
  const [tours, setTours] = useState([]);
 
  const fetchTours = async () => {
    try {
      const response = await axios.get('https://www.selenatour.az/selena/settings.php');
      setTours(response.data[0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchTours();
  }, []);


  return (
    <footer className="pt-[60px] pb-[40px] bg-[#181818] text-white flex justify-center item-center mt-[200px] md:mt-[236px]">
      <div className='md:max-w-container max-w-containerSm'>
      <div className="md:max-w-container max-w-containerSm mx-auto md:flex justify-between gap-8 pb-[54px]">
        <div className='md:w-[409px] md:mr-[205px]'>
          <h3 className="text-xl font-semibold mb-2"><img className='h-[81px] w-[81px]' src={logoFooter}></img></h3>
          <p className='text-[20px] mt-8'>{t("Dünyanı kəşf etmək üçün ilk addımı atın!")}</p>
          <div className='flex gap-3 mt-8'>
            <a href={`${tours.instagram}`}><img className='w-[25px] h-[25px]' src={footerIcon1}></img></a>
            <a href={`${tours.tiktok}`}><img className='w-[25px] h-[25px]' src={footerIcon16}></img></a>
            <a href={`${tours.facebook}`}><img className='w-[25px] h-[25px]' src={footerIcon2}></img></a>
            <a href={`${tours.whatsapp}`}><img className='w-[25px] h-[25px]' src={footerIcon3}></img></a>
          </div>
        </div>
        <div className='md:w-[147px] md:mr-[205px] mt-[64px] md:mt-0'> 
          <p className="text-lg ">{t("Sürətli keçid")}</p>
          <div className="space-y-4 mt-4">
            <div>
            <a href='/'>
            <div className='gap-2 flex'>
              <img className='h-6 w-6' src={footerIcon7}></img>
              <p>{t("Ana səhifə")}</p>
            </div>
            </a>
            </div>
            <div>
            <a href='/about'>
            <div className='gap-2 flex'>
              <img className='h-6 w-6' src={footerIcon7}></img>
              <p>{t("Haqqımızda")}</p>
            </div>
            </a>
            </div>
            <div>
            <a href='/services'>
            <div className='gap-2 flex'>
              <img className='h-6 w-6' src={footerIcon7}></img>
              <p>{t("Xidmətlər")}</p>
            </div>
            </a>
            </div>
            <div>
            <a href='/tours'>
            <div className='gap-2 flex'>
              <img className='h-6 w-6' src={footerIcon7}></img>
              <p>{t("Turlar")}</p>
            </div>
            </a>
            </div>
            <div>
            <a href='/avia'>
            <div className='gap-2 flex'>
              <img className='h-6 w-6' src={footerIcon7}></img>
              <p>{t("Aviabiletlər")}</p>
            </div>
            </a>
            </div>
            <div>
            <a href='/contact'>
            <div className='gap-2 flex'>
              <img className='h-6 w-6' src={footerIcon7}></img>
              <p>{t("Əlaqə")}</p>
            </div>
            </a>
            </div>
</div>

        </div>
        <div className='md:w-[244px] md:flex mt-[64px] md:mt-0'>
        <div>
          <h4 className="text-lg">{t("Əlaqə")}</h4>
          <div className="space-y-4 mt-4">
            <div>
            <div className='gap-2 flex'>
              <img className='h-6 w-6' src={footerIcon4}></img>
              <p>{t(tours.location)}</p>
            </div>
            </div>
            <div>
            <div className='gap-2 flex'>
              <img className='h-6 w-6' src={footerIcon5}></img>
              <p>{tours.mail}</p>
            </div>
            </div>
            <div>
            <div className='gap-2 flex'>
              <img className='h-6 w-6' src={footerIcon6}></img>
              <div>
              <p>{tours.phone1}</p>
              <p>{tours.phone2}</p>
              <p>{tours.phone3}</p>
              <p>{tours.phone4}</p>
              </div>
            </div>
            </div>
</div>
        </div>
        </div>
      </div>
      {/* <hr></hr>
      <div className="md:flex justify-between md:text-center text-sm mx-auto mt-5">
       <p> Selenatour.az © {t("Bütün hüquqlar qorunur.")}</p>
       <a href='https://www.birsayt.az'><p>{t("Sayt hazırlandı")}: Birsayt.az</p></a>
      </div> */}
      </div>
    </footer>
  );
};

export default Footer;
