import React, { useEffect, useState } from 'react';
import { aboutBg, footerIcon7, footerIcon15, tourDetail, detail1, detail2, detail3, detail4, loading, formIcon1, formIcon2, formIcon3, formIcon4 } from '../../assets/images';
import { useTranslation } from 'react-i18next';
import GeneralHeader from '../../Components/home/GeneralHeader/GeneralHeader';
import axios from 'axios';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Modal from "./Modal";
import { translateText } from "../../translate";

 const ToursDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation(); 

  const [tour, setTour] = useState(null);
    const [categories, setCategories] = useState([]);
  const [filteredBlogs, setFilteredBlogs] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // Start with loading true
  const queryParams = new URLSearchParams(location.search);
  const categoryFromUrl = queryParams.get("category") || "";
  const [clientName, setclientName] = useState("");
  const [clientSurname, setclientSurname] = useState("");
  const [clientEmail, setclientEmail] = useState("");
  const [clientPhone, setclientPhone] = useState("");
  const [clientMessage, setclientMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [language, setLanguage] = useState(localStorage.getItem("language") || "az");


   const fetchCategories = async () => {
        try {
          const response = await axios.get('https://www.selenatour.az/selena/categories.php');
          setCategories(response.data.data);
          console.log(response.data.data)
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
        useEffect(() => {
          fetchCategories();
        }, []);


  
  const handleCategoryClick = (category) => {
    navigate(`/tours?category=${category}`);
  };

      const [translatedTitles, setTranslatedTitles] = useState({});
  
  useEffect(() => {
    const translateTours = async () => {
      const newTranslations = {};
      for (const tour of categories) {
        try {
          newTranslations[tour.id] = await translateText(tour.name, language);
        } catch (error) {
          console.error("Çeviri hatası:", error);
          newTranslations[tour.id] = tour.name; // Hata olursa orijinal ismi kullan
        }
      }
      setTranslatedTitles(newTranslations);
    };
  
    if (categories.length > 0) {
      translateTours();
    }
  }, [language, categories]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get("https://www.selenatour.az/selena/tours.php", { withCredentials: false });
        const filteredTour = response.data.data.find(product => product.id == id);
        setTour(filteredTour);
        // Filter the tours based on the selected category
        if (categoryFromUrl) {
          setFilteredBlogs(filteredTour.category2 === categoryFromUrl ? [filteredTour] : []);
        } else {
          setFilteredBlogs([filteredTour]);
        }
        setIsLoading(false)
        
      } catch (error) {
        console.error("Veri alınırken hata oluştu:", error);
      }
    };

    getData();
  }, [id, categoryFromUrl]);

  const handleSubmitContact = async (e) => {
    e.preventDefault();
  
    let inputs = { name: clientName, surname: clientSurname, email: clientEmail, phone: clientPhone, message: clientMessage };
    
    try {
      console.log(inputs);
      
      // Seçilen ürünleri post isteği ile gönder
      const response = await axios.post("https://www.selenatour.az/selena/contact.php/", inputs);
      
      
      // Clear form after successful submission
      setclientName('');
      setclientSurname('');
      setclientEmail('');
      setclientPhone('');
      setclientMessage('');
      
      navigate('/');
      
    } catch (error) {
      // Hata durumunda yapılacak işlemler 
      console.error("Sipariş gönderilirken bir hata oluştu:", error);
    }
  };

    const [translatedText, setTranslatedText] = useState("");
    const [translatedText2, setTranslatedText2] = useState("");
    const [translatedText3, setTranslatedText3] = useState("");
    const [translatedText4, setTranslatedText4] = useState("");
   
    // Çeviri işlemi
    const translate = async (textToTranslate, textToTranslate2, textToTranslate3) => {
      try {
        const translated = await translateText(textToTranslate, language);
        const translated2 = await translateText(textToTranslate2, language);
        const translated3 = await translateText(textToTranslate3, language);
        setTranslatedText(translated); 
        setTranslatedText2(translated2); 
        setTranslatedText3(translated3);
      } catch (error) {
        console.error("Error translating text:", error);
        setTranslatedText(textToTranslate); // Hata durumunda orijinal metni döndür
        setTranslatedText2(textToTranslate2); // Hata durumunda orijinal metni döndür
        setTranslatedText3(textToTranslate3); // Hata durumunda orijinal metni döndür
      }
    };

  
    useEffect(() => {
      // Language changed, update translation for filtered blogs
      if (filteredBlogs.length > 0) {
        translate(filteredBlogs[0].description, filteredBlogs[0].name, filteredBlogs[0].days);  // Çeviri işlemini burada yapabiliriz
      }
    }, [language, filteredBlogs]);

    const getCategoryName = (category2) => {
      const button = categories.find(btn => btn.category2 === category2);
      return button ? button.name : "";
    };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <img src={loading} alt="Loading..." className="w-[300px] h-[300px] md:w-[350px] md:h-[350px]" />
      </div>
    );
  }


  return (
    <>
      <div style={{ height: window.innerWidth < 640 ? '246px' : '246px', backgroundImage: `url(${aboutBg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
        <GeneralHeader />
        <div className='text-center pt-10'>
          <p className='text-[24px] md:text-[32px] font-medium text-white font-titleFont'>{t("Turlar")}</p>
          <div className='flex gap-2 justify-center text-white mt-3'>
          <p className='text-[14px] md:text-[16px]'><a href='/'>{t("Ana səhifə")}</a></p>
            <img className='w-6 h-6' src={footerIcon7} alt="icon" />
            <p className='text-[14px] md:text-[16px]'><a href='/tours'>{t("Turlar")}</a></p>
            <img className='w-6 h-6' src={footerIcon7} alt="icon" />
            <p className='text-[14px] md:text-[16px]'>{t(getCategoryName(tour.category2))}</p>
          </div>
        </div>
      </div> 
      <div className="mt-[68px] md:max-w-container max-w-containerSm mx-auto">
              <div className='md:flex gap-10'>
                <div className="md:w-[285px]">
                  <p className="text-[24px] font-medium font-titleFont mb-10">{t("İstiqamətlər")}</p>
                  {categories.map((button) => (
                                <button
                                  key={button.id}
                                  onClick={() => handleCategoryClick(button.category2)}
                                  className={`border-2 hover:bg-[#FEC919] w-full border-[#FEC919] rounded-[4px] flex justify-between px-[25px] h-[54px] mt-5 items-center ${
                                    categoryFromUrl === button.category2 ? "bg-[#FEC919]" : "bg-[white]"
                                  }`}
                                >
                                  <p className="text-center text-[20px] font-medium font-titleFont">{t(translatedTitles[button.id] || button.name)}</p>
                                  <img className="w-[28px] h-[28px]" src={footerIcon15} alt="icon" />
                                </button>
                              ))}
                </div>
                <div className='md:w-[935px] mt-[72px] md:mt-0'>
                  <p className='text-[24px] text-[#FEC919] font-medium font-titleFont'>{translatedText2}</p>
                  <div className='md:flex gap-10 mt-10'>
                    <div>
                      <img src={`https://www.selenatour.az/selena/${tour.image}`} className='h-auto w-full md:w-[542px]' alt="tour" />
                      <p className="mt-10" dangerouslySetInnerHTML={{ __html: translatedText }} />
                    </div>
                    <div className='md:w-[372px] mt-10 md:mt-0'>
                      <p className='text-[20px] font-medium font-titleFont'>{t("Tur Haqqında Məlumat")}</p>
                      <div className='flex mt-6'>
                        <img className='h-6 w-6 mr-4' src={detail1} alt="detail1" />
                        <p><strong>{t("Günlərin sayı")}: </strong> {translatedText3}</p>
                      </div>
                      <div className='flex mt-6'>
                        <img className='h-6 w-6 mr-4' src={detail2} alt="detail2" />
                        <p><strong>{t("Tarix")}: </strong> {tour.date1}</p>
                      </div>
                      <div className='flex mt-6'>
                        <img className='h-6 w-6 mr-4' src={detail3} alt="detail3" />
                        <p><strong>{t("Qiymət")}: </strong> {tour.price} {tour.exchange}-{t("dən başlayaraq")}</p>
                      </div>
                      {/* <div className='flex mt-6'>
                        <img className='h-6 w-6 mr-4' src={detail4} alt="detail4" />
                        <p>{tour.hotel}</p>
                      </div> */}
                      <a className='block' href='/contact'>
                        <button className='h-[44px] w-full md:w-[315px] mt-[32px] text-white bg-[#FEC919] hover:bg-[#F59A2C] font-titleFont rounded-[33px]'>{t("Müraciət et")}</button>
                      </a>
                      <button onClick={() => setIsModalOpen(true)}  className='h-[44px] w-full md:w-[315px] mt-[16px] md:mt-[32px] text-black border-2 border-[#F59A2C] font-titleFont rounded-[33px]'>{t("Viza xidməti və digər istəklər")}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <div className="flex justify-between items-center mb-4  ">
                  <p className="text-lg font-medium text-[black] font-titleFont ">{t("Formanı doldurun və rezervasiya etmək üçün sizinlə əlaqə saxlayacayıq")}</p>
                  <button onClick={() => setIsModalOpen(false)} className="text-[black] w-[27px] h-[27px]">
                    &#10005;
                  </button>
                </div>
                <form onSubmit={handleSubmitContact}>
                 <div className='grid grid-cols-1 md:grid-cols-2 gap-3'>
                 <div className="mb-4"> 
      
      <div className="relative w-full">
        <input
          type="text"
          className="border border-[#E5E5E5] rounded-[5px] w-full h-[50px] px-3 pr-10 bg-[#FFFFFF] text-black"
          placeholder={t("Ad") + " *"}
          onChange={(event) => setclientName(event.target.value)}
          value={clientName}
          required
        />
        <img src={formIcon1} className='absolute right-3 top-1/2 transform -translate-y-1/2 w-6 h-6 text-gray-400'></img>
      </div>
      
                     
                  </div>
                  <div className="mb-4">
                  <div className="relative w-full">
        <input
          type="text"
          className="border border-[#E5E5E5] rounded-[5px] w-full h-[50px] px-3 pr-10 bg-[#FFFFFF] text-black"
          placeholder={t("Soyad") + " *"}
          onChange={(event) => setclientSurname(event.target.value)}
          value={clientSurname}
          required
        />
        <img src={formIcon1} className='absolute right-3 top-1/2 transform -translate-y-1/2 w-6 h-6 text-gray-400'></img>
      </div>
                     
                  </div>
                 </div>
                  <div className='grid grid-cols-1 md:grid-cols-2 gap-3'>
                  <div className="mb-4">
                  <div className="relative w-full">
        <input
          type="email"
          className="border border-[#E5E5E5] rounded-[5px] w-full h-[50px] px-3 pr-10 bg-[#FFFFFF] text-black"
          placeholder={t("E-poçt ünvanı") + " *"}
          onChange={(event) => setclientEmail(event.target.value)}
          value={clientEmail}
          required
        />
        <img src={formIcon2} className='absolute right-3 top-1/2 transform -translate-y-1/2 w-6 h-6 text-gray-400'></img>
      </div>
                  </div>
                  <div className="mb-4">
                  <div className="relative w-full">
        <input
          type="number"
          className="border border-[#E5E5E5] rounded-[5px] w-full h-[50px] px-3 pr-10 bg-[#FFFFFF] text-black"
          placeholder={t("Telefon") + " *"}
          onChange={(event) => setclientPhone(event.target.value)}
          value={clientPhone}
          required
        />
        <img src={formIcon4} className='absolute right-3 top-1/2 transform -translate-y-1/2 w-6 h-6 text-gray-400'></img>
      </div>
                  </div>
                  </div>
                  <div className="mb-4">
                  <div className="relative w-full">
        <textarea
          className="border rounded-[5px] w-full h-[138px] p-3 pr-10 bg-[#FFFFFF] text-black resize-none"
          placeholder={t("Əlavə şərhlər və istəklər") + " *"}
          onChange={(event) => setclientMessage(event.target.value)}
          value={clientMessage}
          required
        ></textarea>
         <img src={formIcon3} className='absolute right-3 top-1/4 transform -translate-y-1/2 w-6 h-6 text-gray-400'></img>
      </div>
      </div>
      
                  <div className="flex justify-end">
                    <button  type="submit" className="h-[44px] w-full md:w-[151px] mt-[24px] text-white bg-[#FEC919] hover:bg-[#F59A2C] font-titleFont rounded-[33px]">{t("Göndər")}</button>
                  </div>
                </form>
            </Modal>  
    </>
  );
};

export default ToursDetail;