import axios from "axios";

const API_KEY = "AIzaSyBS5sfM-CpijVtZRP_orjTTK-eluq_5MaM"; // Buraya kendi API anahtarınızı ekleyin

export const translateText = async (text, targetLang) => {
  try {
    const response = await axios.post(
      `https://translation.googleapis.com/language/translate/v2`,
      {},
      {
        params: {
          q: text,
          target: targetLang,
          key: API_KEY,
        },
      }
    );

    return response.data.data.translations[0].translatedText;
  } catch (error) {
    console.error("Çeviri hatası:", error);
    return text; // Hata olursa orijinal metni döndür
  }
};
