import React, { useEffect, useState } from 'react';
import { aboutBg, footerIcon7, loading } from '../../assets/images';
import { useTranslation } from 'react-i18next';
import GeneralHeader from '../../Components/home/GeneralHeader/GeneralHeader';
import axios from 'axios';
import { translateText } from "../../translate";

const Avia = () => {
  const { t } = useTranslation();
  const [tours, setTours] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [translatedTours, setTranslatedTours] = useState({});
  const language = localStorage.getItem("language") || "az";

  const fetchTours = async () => {
    try {
      const response = await axios.get('https://www.selenatour.az/selena/avia.php');
      setTours(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }; 

  const translateTour = async (tour) => {
    try {
      const translatedLocations1 = await translateText(tour.locations1, language);
      const translatedLocations2 = await translateText(tour.locations2, language);
      const translatedLuggage = await translateText(tour.luggage, language);

      setTranslatedTours((prev) => ({
        ...prev,
        [tour.id]: {
          locations1: translatedLocations1,
          locations2: translatedLocations2,
          luggage: translatedLuggage,
        },
      }));
    } catch (error) {
      console.error("Error translating text:", error);
    }
  };

  useEffect(() => {
    fetchTours();
  }, []);

  useEffect(() => {
    tours.forEach((tour) => {
      translateTour(tour);
    });
  }, [language, tours]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <img src={loading} alt="Loading..." className="w-[300px] h-[300px] md:w-[350px] md:h-[350px]" />
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          height: window.innerWidth < 640 ? '246px' : '246px',
          backgroundImage: `url(${aboutBg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >  
        <GeneralHeader />
        <div className="text-center pt-10">
          <p className="text-[24px] md:text-[32px] font-medium text-white font-titleFont">{t('Aviabiletlər')}</p>
          <div className="flex gap-2 justify-center text-white mt-3">
            <p className='text-[14px] md:text-[16px]'><a href='/'>{t("Ana səhifə")}</a></p>
            <img className="w-6 h-6" src={footerIcon7} alt="Footer Icon" />
            <p className="text-[14px] md:text-[16px]">{t('Aviabiletlər')}</p>
          </div>
        </div>
      </div>

      <div className="mt-[64px] md:mt-[111px] md:max-w-container max-w-containerSm mx-auto">
        <div className="mt-[40px] grid grid-cols-1 md:grid-cols-4 gap-[20px]">
          {tours.map((tour) => {
            const translated = translatedTours[tour.id] || {};
            return (
              <div key={tour.id} className="border-2 border-[#E5E5E5] rounded-[16px]">
                <img
                  className="mx-auto w-full h-[291px] object-cover rounded-t-[16px]"
                  src={`https://www.selenatour.az/selena/${tour.image}`}
                  alt={`${tour.locations1} - ${tour.locations2}`}
                />
                <div className="pt-[16px] px-[10px] pb-[24px]">
                  <p className="text-[18px] font-medium">
                    {translated.locations1 || tour.locations1} - {translated.locations2 || tour.locations2}
                  </p>
                  <div className="flex justify-between items-center">
                    <p className="text-[15px]">{tour.date1} - {tour.date2}</p>
                    <button className="min-h-[29px] bg-[#FEC919] text-black rounded-[16px] px-[10px]">
                      {tour.price} {tour.exchange}
                    </button>
                  </div>
                  <p>{translated.luggage || tour.luggage}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  ); 
};

export default Avia;
