import React, { useState } from 'react'
import { about1, about2, about3, about4, about5, aboutBg, blogLeft, blogRightBottom, blogRightTop, footerIcon7, hero2, logo, productBg, services1, services2, services3, services4, services5, services6, services7, services8, servicesBg, whyus } from '../../assets/images';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FiMenu, FiX } from 'react-icons/fi';
import GeneralHeader from '../../Components/home/GeneralHeader/GeneralHeader';

const Services = () => {

    const { t, i18n } = useTranslation();
  

  return (
   <>
   <div style={{height: window.innerWidth < 640 ? '246px' : '246px', backgroundImage: `url(${aboutBg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
    <GeneralHeader/>
     
     <div className='text-center pt-10'>
       <p className='text-[24px] md:text-[32px] font-medium text-white font-titleFont'>{t("Xidmətlər")}</p>
       <div className='flex gap-2 justify-center text-white mt-3'>
       <p className='text-[14px] md:text-[16px]'><a href='/'>{t("Ana səhifə")}</a></p>
       <img className='w-6 h-6' src={footerIcon7}></img>
       <p className='text-[14px] md:text-[16px]'>{t("Xidmətlər")}</p>
       </div>
     </div>
     
   </div>
   <div className="mt-[80px] md:max-w-container max-w-containerSm mx-auto">
         <div className='flex justify-between'>
        <div>
        <div className='flex gap-2 items-center'>
               <p className='text-[#FEC919]'>{t("Xidmətlər")}</p>
               <div className='w-[43px] h-[1px] bg-[#FEC919]'></div>
               </div> 
               <p className='text-[28px] font-medium text-[#0E0E0E] font-titleFont'>{t("Yüksək keyfiyyətli xidmətlər təqdim edirik")}</p>
        </div>
         </div>
         <div className='mt-[40px] grid grid-cols-1 md:grid-cols-4 gap-[20px]'>
      <div className='border-2 border-[#E5E5E5] hover:border-[#FEC919] rounded-[16px] py-5'>
      <img className='mx-auto w-[46px] h-[46px]' src={services1}></img>
      <p className='mt-[12px] text-center font-medium font-titleFont'>	{t("Tur Paketlərinin Təşkili")}</p>
      <p className='mt-[8px] text-[14px] text-[#696767] text-center'>	•	{t("Fərdi və qrup turları")}</p>
  <p className='mt-[8px] text-[14px] text-[#696767] text-center'>	
	•	{t("Ekzotik, mədəni və macəra turları")}</p>
  <p className='mt-[8px] text-[14px] text-[#696767] text-center'>	
	•	{t("Kruiz turları")}</p>
      </div>
      <div className='border-2 border-[#E5E5E5] hover:border-[#FEC919] rounded-[16px] py-5'>
      <img className='mx-auto w-[46px] h-[46px]' src={services2}></img>
      <p className='mt-[12px] text-center font-medium font-titleFont'>	{t("Viza dəstəyi")}</p>
      <p className='mt-[8px] text-[14px] text-[#696767] text-center'>  •	{t("Sənədlərin hazırlanması")}</p>
  <p className='mt-[8px] text-[14px] text-[#696767] text-center'>	
  •	{t("Konsultasiya")}</p>
  <p className='mt-[8px] text-[14px] text-[#696767] text-center'>	
  •	{t("Viza müraciətlərinin izlənməsi")}</p>
      </div>
      <div className='border-2 border-[#E5E5E5] hover:border-[#FEC919] rounded-[16px] py-5'>
      <img className='mx-auto w-[46px] h-[46px]' src={services3}></img>
      <p className='mt-[12px] text-center font-medium font-titleFont'>	 {t("Aviabiletlərin Satışı")}</p>
      <p className='mt-[8px] text-[14px] text-[#696767] text-center'>		•	{t("Fərdi və korporativ uçuş biletləri")}</p>
  <p className='mt-[8px] text-[14px] text-[#696767] text-center'>	
  •	{t("Endirimli və xüsusi təkliflər")}</p>
      </div>
      <div className='border-2 border-[#E5E5E5] hover:border-[#FEC919] rounded-[16px] py-5'>
      <img className='mx-auto w-[46px] h-[46px] ' src={services4}></img>
      <p className='mt-[12px] text-center font-medium font-titleFont'>	{t("Otel və Apartament Rezervasiyası")}</p>
      <p className='mt-[8px] text-[14px] text-[#696767] text-center'>		•	{t("Büdcəyə uyğun və lüks otellər")}
      </p>
  <p className='mt-[8px] text-[14px] text-[#696767] text-center'>	
	•	{t("Xüsusi endirimlər və təkliflər")}</p>
      </div>
      <div className='border-2 border-[#E5E5E5] hover:border-[#FEC919] rounded-[16px] py-5'>
      <img className='mx-auto w-[46px] h-[46px]' src={services5}></img>
      <p className='mt-[12px] text-center font-medium font-titleFont'>	{t("MICE Xidmətləri (Biznes Turizm)")}</p>
      <p className='mt-[8px] text-[14px] text-[#696767] text-center'>		•	{t("Korporativ tədbirlər və sərgilər")}</p>
  <p className='mt-[8px] text-[14px] text-[#696767] text-center'>	
  •	{t("Konfransların təşkili")}</p>
  <p className='mt-[8px] text-[14px] text-[#696767] text-center'>	
  •	{t("İşgüzar səfərlər")}</p>
      </div>
      <div className='border-2 border-[#E5E5E5] hover:border-[#FEC919] rounded-[16px] py-5'>
      <img className='mx-auto w-[46px] h-[46px]' src={services6}></img>
      <p className='mt-[12px] text-center font-medium font-titleFont'>		{t("Müalicəvi və sağlamlıq turları")}</p>
      <p className='mt-[8px] text-[14px] text-[#696767] text-center'>		•	{t("Sanatoriya və spa mərkəzlərinə rezervasiya")}</p>
      </div>
      <div className='border-2 border-[#E5E5E5] hover:border-[#FEC919] rounded-[16px] py-5'>
      <img className='mx-auto w-[46px] h-[46px]' src={services7}></img>
      <p className='mt-[12px] text-center font-medium font-titleFont'>	{t("Avtomobil Kirayəsi və Transferlər")}</p>
      <p className='mt-[8px] text-[14px] text-[#696767] text-center'>		•	{t("Hava limanı transferləri")}</p>
  <p className='mt-[8px] text-[14px] text-[#696767] text-center'>	
  •	{t("Şəxsi sürücü xidməti")}</p>
      </div>
      <div className='border-2 border-[#E5E5E5] hover:border-[#FEC919] rounded-[16px] py-5'>
      <img className='mx-auto w-[46px] h-[46px]' src={services8}></img>
      <p className='mt-[12px] text-center font-medium font-titleFont'>	{t("Sığorta")}</p>
      <p className='mt-[8px] text-[14px] text-[#696767] text-center'>		•	{t("Səyahət")}</p>
  <p className='mt-[8px] text-[14px] text-[#696767] text-center'>	
  •	{t("Avtomobil")}</p>
  <p className='mt-[8px] text-[14px] text-[#696767] text-center'>	
  •	{t("Əmlak")}</p>
      </div>
         </div>
         <div>
           
         </div>
       </div>
       </>
  )
}

export default Services