import React from 'react'
import { contactBg, countries1 } from '../../../assets/images'
import { useTranslation } from 'react-i18next';

const Contact = () => {

    const { t, i18n } = useTranslation();

  return (
  

 <div className="mt-[120px] md:max-w-container w-full mx-auto ">
        <div
    style={{
      backgroundImage: `url(${contactBg})`,
      backgroundSize: 'cover', 
      backgroundPosition: 'center',
      height: '268px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center', // Dikeyde ortalar
      alignItems: 'center', // Yatayda ortalar
      textAlign: 'center',
      borderRadius: window.innerWidth < 640 ? '20px' : '36px',
    }} 
  >
    <p className='text-center text-white text-[22px] md:text-[40px] font-medium font-titleFont'>
      {t("24/7 Müştəri Dəstəyi")}
    </p>
    <p className='text-center text-white md:text-[18px] mt-3 max-w-containerSm md:max-w-full'>
      {t("Səyahət zamanı yardım və dəstək xidmətimizlə hər zaman yanınızdayıq.")}
    </p>
    <a href='/contact'><button className='h-[44px] w-[206px] md:w-[244px] font-titleFont mt-[24px] md:mt-[32px] bg-[#FEC919] hover:bg-[#F59A2C] text-white rounded-[33px]'>{t("Ətraflı bax")}</button></a>
  </div>
    </div>
  )
}

export default Contact