import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { about1, about2, about3, about4, countries1, countries2, countries3, countries4, hero2, heroBg, locations1, locations2, locations3, locations4 } from '../../../assets/images';


const Cities = () => {

  const [blogs, setBlogs] = useState([]);
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeButton, setActiveButton] = useState("Sərgilər");
  const buttons = [
    { key: "Sərgilər", label: "Sərgilər", type: "sergi" },
    { key: "Təlimlər", label: "Təlimlər", type: "telim" },
  ];

  const { t, i18n } = useTranslation();

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    // Filter blogs based on the active button
    if (activeButton) {
      const selectedType = buttons.find((btn) => btn.key === activeButton)?.type;
      if (selectedType) {
        setFilteredBlogs(blogs.filter((blog) => blog.type === selectedType).slice(0, 3));
      } else {
        setFilteredBlogs(blogs.slice(0, 3)); // Show only the first 3 if no type is matched
      }
    }
  }, [activeButton, blogs]);
  
  const getProducts = async () => {
    try {
      const response = await axios.get(
        `https://www.agrogurama.az/agrogurama/blogs.php`,
        { withCredentials: false }
      );
      const fetchedBlogs = response.data.data;
      setBlogs(fetchedBlogs);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>; // Show loading indicator while data is being fetched
  } 

  return (
     <div className="mt-[72px] md:mt-[120px] md:max-w-container max-w-containerSm mx-auto ">
      <div className='grid grid-cols-1 md:grid-cols-4 gap-[20px]'>
     <div>
      <p className='text-[28px] font-medium font-titleFont'>{t("Unudulmaz Təcrübələr Üçün Ən Yaxşı Məkanlar")}</p>
     <div className='relative mt-[28px]'>
     <img className='h-[193px] w-full' src={locations1}></img>
      <div className='absolute top-3 left-3 text-white gap-[7px] flex items-center'>
      <img className='w-[26px] h-[17px]' src={countries1}></img>
      <p className='font-medium font-titleFont'>{t("Fransa")}</p>
  </div>
     </div>
     </div>
     <div>
      <div className='relative'>
     <img className='h-[347px] w-full' src={locations2}></img>
      <div className='absolute top-3 left-3 text-white gap-[7px] flex items-center'>
      <img className='w-[26px] h-[17px]' src={countries2}></img>
      <p className='font-medium font-titleFont'>{t("İtaliya")}</p>
  </div>
     </div>
     </div>
     <div>
     <div className='relative'>
     <img className='h-[347px] w-full' src={locations3}></img>
      <div className='absolute top-3 left-3 text-white gap-[7px] flex items-center'>
      <img className='w-[26px] h-[17px]' src={countries3}></img>
      <p className='font-medium font-titleFont'>{t("İspaniya")}</p>
  </div>
     </div>
     </div>
     <div>
     <div className='relative'>
     <img className='h-[170px] w-full' src={locations4}></img>
      <div className='absolute top-3 left-3 text-white gap-[7px] flex items-center'>
      <img className='w-[26px] h-[17px]' src={countries4}></img>
      <p className='font-medium font-titleFont'>{t("Maldiv adaları")}</p>
  </div>
     </div>
      <p className='text-[16px] mt-[33px]'>{t("Dünyanın ən maraqlı və gözəl yerlərinə səyahət etmək, həyatınıza yeni bir rəng qatacaq. Hər bir məkan özünəməxsus tarixi, mədəniyyəti və təbii gözəlliyi ilə sizi möhtəşəm təcrübələrlə qarşılayacaq.")}</p>
     </div>
      </div>
    </div>
  );
};

export default Cities;
